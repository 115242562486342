import styles from '../../styles/headless.module.css';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Measurements, Space, Units, useViewform } from '@vrpm/viewform';
import {
  AnnotationDetails,
  Euler,
  GroupDetails,
  ICameraDetails,
  IViewformTour,
} from '@vrpm/viewform-contracts';
import { TourOptions } from '@vrpm/viewform-contracts';
import { JumpMode } from '@vrpm/viewform-contracts';
import { getCameraFromTour, getGroupsFromTour } from '@/utils/viewform-utils';
import { Group } from './group';
import _ from 'lodash';

export interface ViewformProps {
  tour: IViewformTour;
  tourOptions: TourOptions;
  startCameraId: string;
  onTagClick: (tag: AnnotationDetails) => void;
  onGroupChanged: (groupName: string) => void;
  onJumpEnd: (e: ICameraDetails) => void;
  onRotationStart: (value: Euler) => void;
  exteriorCameras?: string[];
  onGroupSphereClicked: (groupName: string) => void;
  groupSphereColor: string;
  activeGroups: GroupDetails[];
}

const Viewform = ({
  tour,
  startCameraId,
  tourOptions,
  onTagClick,
  onGroupChanged,
  onJumpEnd,
  onRotationStart,
  exteriorCameras,
  onGroupSphereClicked,
  groupSphereColor,
  activeGroups,
}: ViewformProps) => {
  const [startingImageVersionName, setStartingImageVersionName] = useState(
    tour.imageVersions[0].name,
  );
  const [currentCamera, setCurrentCamara] = useState<string>(startCameraId);

  useEffect(() => {
    const startingVersionId =
      tour.startingImageVersionId ?? tour.imageVersions[0].id;
    const version = tour.imageVersions.find((v) => v.id == startingVersionId);
    setStartingImageVersionName(version?.name ?? tour.imageVersions[0].name);
  }, [tour]);

  const showGroupHotspot = _.indexOf(exteriorCameras, currentCamera) >= 0;
  const tourStartCameraId = startCameraId ?? tour.startingCameraId!;
  const startCameraRotation = useMemo(() => {
    const camera = getCameraFromTour(tour, startCameraId);

    if (camera.type === 'viewformCameraDetails') {
      return camera.startingRotation;
    } else {
      return tour.startingCameraRotation;
    }

  }, [tour, startCameraId])

  console.log({ tour});

  return (
    <div
      style={{
        height: '100svh',
        width: '100vw',
        maxHeight: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {tour && tour.sasInfo && tourOptions && (
        <div className={styles.space}>
          <Space
            tour={tour}
            startCamerRotation={startCameraRotation}
            sasInfo={tour.sasInfo}
            startCameraId={tourStartCameraId}
            imageVersion={startingImageVersionName}
            displayBanner={false}
            displayTags={tourOptions.viewformSettings.displayTags}
            displayToolbar={false}
            // displayToolbar={tourOptions.viewformSettings.displayToolbar}
            enableResolutionPicker={
              tourOptions.viewformSettings.displayToolbar &&
              tourOptions.viewformSettings.displayResolutionSelector
            }
            enableVersionPicker={
              tourOptions.viewformSettings.displayToolbar &&
              tourOptions.viewformSettings.displayImageVersionSelector
            }
            displayLoadingScreen={
              tourOptions.viewformSettings.displayLoadingScreen
            }
            loadingStyle={tourOptions.viewformSettings.loadingStyle}
            displayHotspots={tourOptions.viewformSettings.displayHotspots}
            hotspotAnimation={tourOptions.viewformSettings.hotspotAnimation}
            hotspotShape={tourOptions.viewformSettings.hotspotShape}
            hotspotColor={tourOptions.viewformSettings.hotspotColor}
            jumpMode={
              Number(tourOptions.viewformSettings.hotspotJumpMode) as JumpMode
            }
            enablePitchCorrection={
              tourOptions.viewformSettings.enablePitchCorrection
            }
            enableYawCorrection={
              tourOptions.viewformSettings.enableYawCorrection
            }
            displayCursor={true}
            disableCameraControls={false}
            hotspotBuilder={undefined}
            onTagClick={(e) => {
              console.log('[Viewform+] Tag clicked', e);
              onTagClick(e);
            }}
            onModelClick={(e) => console.log('[Viewform+] Model clicked', e)}
            onModelPointerMove={(e) => {
              //   console.log('[Viewform+] Pointer moved over model', e)
            }}
            onModelPointerEnter={(e) => {
              //   console.log('[Viewform+] Pointer entered model', e)
            }}
            onGroupChanged={(current) => {
              console.log('[Viewform+] curent room', current);
              onGroupChanged(current);
            }}
            onModelPointerLeave={(e) =>
              console.log('[Viewform+] Pointer left model', e)
            }
            onJumpStart={(current, next) => {
              console.log('[Viewform+] Jump start', current, next);
            }}
            onJumpEnd={(e) => {
              console.log('[Viewform+] Jump ended', e);
              setCurrentCamara(e.id);
              onJumpEnd(e);
            }}
            onRotationChange={(e) =>
              console.log('[Viewform+] Rotation change', e)
            }
            onRotationStart={(e) => {
              console.log('[Viewform+] Rotation start', e);
              onRotationStart(e);
            }}
            onRotationEnd={(e) => {
              console.log('[Viewform+] Rotation end', e);
            }}
            debug={false}
          >
            <Measurements units={Units.Metric} />

            {showGroupHotspot && (<group>
              {activeGroups.map((g) => (
                  <Fragment key={g.name}>

                    <Group
                      name={g.name}
                      cameras={g.cameras!}
                      tags={g.annotations!}
                      group={g}
                      tour={tour}
                      onGroupSphereClicked={onGroupSphereClicked}
                      alwaysShowBoundingBox={true}
                      showBoundingBoxOnHover={true}
                      groupSphereColor={groupSphereColor}
                    />
                  </Fragment>
                ))}
              </group>
            )}
          </Space>
        </div>
      )}
    </div>
  );
};
export default Viewform;
