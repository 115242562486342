import styles from './audio-player.module.css'
import customLoader from '../custom-loader'
import Image from 'next/image';
import react, { useContext, useEffect, useRef } from "react";
import React from 'react';
import Icon from '../icon/icon';
import Avatar from '../avatar/avatar';
import dynamic from "next/dynamic";
import Rive from 'rive-react';
import { useRive, useStateMachineInput } from 'rive-react';
import { StateContext } from '../../lib/state_provider';
import ReactPlayer from 'react-player';



 function AudioPlayer({src}:any){
  const bs = useContext(StateContext) 





    return <div className={styles.wrapper} >

           
           
            
           <ReactPlayer playing={bs.playingAudio && bs.playingAudio == src} url={src} controls width={"100%"} height={"40px"} onPlay={()=>{
             bs.toggleAudioPlaying(src)
           }} onPause={()=>{
            bs.toggleAudioPlaying(null)
          }} />
          
    </div>

}

export default AudioPlayer;