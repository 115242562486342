import styles from './mdx-remote-view.module.css';
import { MDXRemote, MDXRemoteProps } from 'next-mdx-remote';

const components = {
  a: (props: any) => (
    <a {...props} className={styles.link} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  ),
};

export default function MDXRemoteView(props: MDXRemoteProps) {
  return (
    <MDXRemote
      {...props}
      components={{ ...components, ...(props.components || {}) }}
    />
  );
}
