import { useState } from 'react';
import { ExteriorCamera } from '@/types';
import _ from 'lodash';

export type NavigationProps = {
  navigationCameras: ExteriorCamera[];
  onNavClick: (camera: ExteriorCamera) => void;
};

export default function Navigation({
  navigationCameras,
  onNavClick,
}: NavigationProps) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);


  return (
    <div className="bg-white w-[135px]">
      <div
        className="flex flex-col justify-center items-center bg-white"
        style={{
          display: isMenuOpen ? undefined : 'none',
        }}
      >
        {navigationCameras?.map((camera) => (
          <button
            key={camera.id}
            className={
              'menu-item text-black px-4 h-[50px] bg-white cursor-pointer pointer-events-auto w-full text-left hover:bg-black hover:text-white'
            }
            onClick={() => {
                setIsMenuOpen(false);
              _.isFunction(onNavClick) && onNavClick(camera);
            }}
          >
            {camera.label}
          </button>
        ))}
      </div>

      <button
        className="pointer-events-auto cursor-pointer w-full text-black px-4 h-[50px] bg-white "
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        Navigation
      </button>
    </div>
  );
}
