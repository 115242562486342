import { MouseEvent } from 'react';
import styles from './group-hotspot.module.css';
import _ from 'lodash';

export type GroupHotspotProps = {
  title: string;
  description?: string;
  imageUrl: string;
  onClick?: (e: MouseEvent) => void;
};
export default function GroupHotspot({
  title,
  description,
  imageUrl,
  onClick,
}: GroupHotspotProps) {
  return (
    <div className={styles.popup} onClick={(e) => _.isFunction(onClick) &&  onClick(e)}>
      {imageUrl && (
        <div className={styles.popupImage}>
          <img alt="room avatar" src={imageUrl} />
        </div>
      )}
      <div className={styles.popupTitle}>
        <h3>{title}</h3>
        {description && <p>{description}</p>}
      </div>
    </div>
  );
}
