import styles from './avatar.module.css';
import customLoader from '../custom-loader';
import Image from 'next/image';
import { Media } from '@/types';
import _ from 'lodash';

export type AvatarProps = {
  media: Media;
};
function Avatar({ media }: AvatarProps) {
  if (_.isEmpty(media)) {
    return null;
  }

  const fallbackUrl = media.url || '';

  return (
    <div className={styles.avatar}>
      <Image
        alt="Avatar"
        layout="fill"
        objectFit="cover"
        placeholder="blur"
        blurDataURL={media.formats?.small?.url || fallbackUrl}
        src={media.formats?.medium?.url || fallbackUrl}
        loader={customLoader}
        unoptimized={true}
      />
    </div>
  );
}

export default Avatar;
