import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

interface DeviceContextModel {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  width: number;
  height: number;
}

const initial: DeviceContextModel = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  width: 0,
  height: 0,
};

const Context = createContext(initial);

export const useDeviceContext = () => useContext(Context);

interface DeviceContextProps {
  mobileBreakPoint?: number;
  desktopBreakPoint?: number;
}

export const DeviceContextProvider: FC<
  PropsWithChildren<DeviceContextProps>
> = ({ mobileBreakPoint = 700, desktopBreakPoint = 1200, children }) => {
  const [props, setProps] = useState<DeviceContextModel>(initial);

  useEffect(() => {
    const updateProps = () => {
      const w = window?.innerWidth;
      const h = window?.innerHeight;
      setProps({
        isMobile: w <= mobileBreakPoint,
        isTablet: w > mobileBreakPoint && w <= desktopBreakPoint,
        isDesktop: w > desktopBreakPoint,
        width: w,
        height: h,
      });
    };

    updateProps();
    window?.addEventListener('resize', updateProps);
    return () => window?.removeEventListener('resize', updateProps);
  }, [desktopBreakPoint, mobileBreakPoint]);

  return <Context.Provider value={props}>{children}</Context.Provider>;
};
