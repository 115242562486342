import { InternalServiceApi } from "@/apis/internal.api";
import ViewformUtils from "@/utils/viewform-utils";
import { TourOptions, ViewformTour } from "@vrpm/viewform-contracts";
import { useEffect, useState } from "react";

export function useTourFetch() {
  const [tour, setTour] = useState(ViewformUtils.default());
  const [tourOptions, setTourOptions] = useState(
    undefined as TourOptions | undefined,
  );

  useEffect(() => {
    const initializeTour = async () => {
      try {
        const tourId = process.env.NEXT_PUBLIC_TARGET_TOUR_ID as string;

        const dataType = process.env.NEXT_PUBLIC_DATA_SOURCE_TYPE as string;
        if (dataType == null) {
          console.error(
            'Data type is not defined. Please define NEXT_PUBLIC_DATA_SOURCE_TYPE in .env file',
          );
          return;
        }

        const tourDataResponse = await InternalServiceApi.getTour(
          tourId,
          dataType,
        );
        console.log('tourDataResponse', tourDataResponse);
        if (tourDataResponse) {
          const tourData = { ...tourDataResponse } as ViewformTour;
          setTour(tourData);
        }
        console.log('Loaded tour data successfully');

        const tourOptionsResponse = await InternalServiceApi.getTourOptions(
          tourId,
          dataType,
        );
        if (tourOptionsResponse) {
          const tourOptions = TourOptions.from(tourOptionsResponse);
          setTourOptions(tourOptions);
        }
        console.log('Loaded tour options data successfully');
      } catch (error) {
        console.error('Failed to fetch tour data:', error);
      }
    };

    initializeTour();
  }, []);

  return { tour, tourOptions };

}