import styles from "./product-list-item.module.css";
import customLoader from "../custom-loader";
import Image from "next/image";
import React from "react";
function ProductListItem({ name, shopName, photoUrl, onClick, ctaUrl, ctaLabel }: any) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.coverPhoto}>
                {photoUrl && (
                    <Image
                        alt=""
                        layout="fill"
                        objectFit={"cover"}
                        src={photoUrl}
                        loader={customLoader}
                        unoptimized={true}
                    />
                )}
            </div>

            <div className={styles.cta}>
                <h5>
                    {name} <br /> <span>{shopName}</span>
                </h5>
                <a
                    className={styles.purchase}
                    onClick={onClick}
                    href={ctaUrl}
                    rel={"noreferrer"}
                    target="_blank"
                >
                    {ctaLabel ?? 'PURCHASE'}
                </a>
            </div>
        </div>
    );
}

export default ProductListItem;
