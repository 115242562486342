/**
 * NOTE: Please be careful updating this component.
 * It might break unexpectedly when you add dependencies in React Hooks.
 */
import styles from './page-view.module.css';
import {
  useSpring,
  animated,
  useSprings,
} from 'react-spring';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';

import useMeasure from 'react-use-measure';
import { useDrag, useWheel } from 'react-use-gesture';

function clamp(value: number, min: number, max: number) {
  return Math.max(min, Math.min(max, value));
}

function PageViewComponent({
  lightMode,
  current,
  count,
  builder,
  nextBuilder,
  prevBuilder,
  onCurrentChange,
}: any) {
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const index = useRef(current != undefined ? current : 0);
  const [ref, { width }] = useMeasure();
  const [props, api] = useSprings(
    count,
    (i) => ({
      x: (i - index.current) * width,
      scale: 1,
      display: 'block',
      config: { mass: 0.1, tension: 10, friction: 2, velocity: 0.0 },
    }),
    [width],
  );
  useEffect(() => {
    setCurrentIndex(0);
    index.current = 0;
    updatePositions(false, 0, 0);
  }, [count]);
  const nextSpring = useSpring({
    scale: currentImageIndex >= count - 1 ? 0 : 1,
  });
  const prevSpring = useSpring({
    scale: currentImageIndex > 0 ? 1 : 0,
  });

  const gotoPrevious = () => {
    index.current = clamp(index.current - 1, 0, count - 1);
    updatePositions(false, 0, 0);
    setCurrentIndex(index.current);
  };
  const gotoNext = () => {
    index.current = clamp(index.current + 1, 0, count - 1);
    updatePositions(false, 0, 0);
    setCurrentIndex(index.current);
  };

  const updatePositions = (active: boolean, distance: number, mx: number) => {
    api.start((i) => {
      if (i < index.current - 1 || i > index.current + 1)
        return { display: 'none' };
      const x = (i - index.current) * width + (active ? mx : 0);
      const scale = 1; // active ? 1 - distance / width / 4 : 1
      return { x, scale, display: 'block' };
    });
    onCurrentChange && onCurrentChange(index.current);
  };

  const bind = useDrag(
    ({
      active,
      movement: [mx],
      direction: [xDir],
      distance,
      cancel,
      event,
    }) => {
      if (count == 1) return;
      console.log(distance, mx, width);
      event.stopPropagation();
      event.preventDefault();
      if (active && distance > width / 2) {
        index.current = clamp(
          index.current + (xDir > 0 ? -1 : 1),
          0,
          count - 1,
        );
        setCurrentIndex(index.current);
        cancel();
      }
      updatePositions(active, distance, mx);
    },
  );
  const bindWheel = useWheel(
    ({
      active,
      movement: [my, mx],
      direction: [yDir, xDir],
      distance,
      cancel,
    }) => {
      if (count == 1) return;
      console.log(distance, mx, width, xDir);
      if (active && distance > width / 3) {
        console.log('inside eval', distance > width / 2);
        index.current = clamp(
          index.current + (xDir > 0 ? -1 : 1),
          0,
          count - 1,
        );
        setCurrentIndex(index.current);
        cancel();
      }
      updatePositions(active, distance, mx);
    },
  );
  var style = { backgroundColor: lightMode ? 'white' : 'black' };
  var invertStyle = { filter: lightMode ? 'invert(0)' : 'invert(1)' };
  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.imageWrapper} style={style}>
        {props.map(({ x, display, scale }, i) => (
          <animated.div
            className={styles.page}
            {...bind()}
            {...bindWheel()}
            key={i}
            style={{ display, x, zIndex: count - i }}
          >
            <animated.div style={{ scale }}>{builder(i)}</animated.div>
          </animated.div>
        ))}
      </div>

      <div className={styles.controls}>
        {count > 1 && prevBuilder && (
          <animated.a
            className={styles.button + ' prev'}
            style={prevSpring}
            onClick={() => gotoPrevious()}
          >
            {prevBuilder()}
          </animated.a>
        )}

        {count > 1 && nextBuilder && (
          <animated.a
            className={styles.button + ' next'}
            style={nextSpring}
            onClick={() => gotoNext()}
          >
            {nextBuilder()}
          </animated.a>
        )}
      </div>
      {count > 1 && (
        <div style={invertStyle} className={styles.dots}>
          {[...new Array(count)].map((_, i) => {
            return (
              <div
                className={
                  styles.dot +
                  (currentImageIndex == i ? ' ' + styles.focus : '')
                }
                key={i.toString()}
              ></div>
            );
          })}
        </div>
      )}
    </div>
  );
}
export default PageViewComponent;
