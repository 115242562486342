import styles from "./room-header-mini.module.css";
import { useContext, useEffect } from "react";
import React from "react";
import Avatar from "../avatar/avatar";
import { useRive, useStateMachineInput } from "rive-react";
import { StateContext } from "../../lib/state_provider";
function RoomHeaderMini({
    name,
    sponsors,
    floorName,
    avatar,
    onClick,
    audioSrc,
    isPlaying
}: any) {
    const bs = useContext(StateContext);
    const STATE_MACHINE_NAME = "sm";
    const INPUT_PLAYING_NAME = "playing";
    const INPUT_HOVER_NAME = "hover";

    const { RiveComponent, rive } = useRive({
        src: "animations/audioripples.riv",
        stateMachines: STATE_MACHINE_NAME,
        autoplay: true
    });

    const onPlayingInput = useStateMachineInput(
        rive,
        STATE_MACHINE_NAME,
        INPUT_PLAYING_NAME
    );
    const onHoverInput = useStateMachineInput(
        rive,
        STATE_MACHINE_NAME,
        INPUT_HOVER_NAME
    );

    useEffect(() => {
        if (!onPlayingInput) return;
        onPlayingInput.value = isPlaying;
    }, [onPlayingInput, isPlaying]);

    return (
        <div className={styles.wrapper + " button"} onClick={onClick}>
            {avatar && <Avatar media={avatar} />}
            <div className={styles.text}>
                <h3>
                    <b>{name}</b>
                </h3>
                {sponsors && <p>{sponsors}</p>}
            </div>
            <div
                className={styles.audioPlaying}
                onClick={(e) => {
                    if (isPlaying) {
                        e.stopPropagation();
                        bs.toggleAudioPlaying(null);
                        if (onPlayingInput) {
                            onPlayingInput.value = !onPlayingInput.value;
                        }
                    }
                }}
                onMouseEnter={() => {
                    if (onHoverInput) onHoverInput.value = true;
                }}
                onMouseLeave={() => {
                    if (onHoverInput) onHoverInput.value = false;
                }}
            >
                <RiveComponent></RiveComponent>
            </div>
        </div>
    );
}

export default RoomHeaderMini;
