import styles from './designer-card.module.css';
import customLoader from '../custom-loader';
import Image from 'next/image';
import React from 'react';
import Icon from '../icon/icon';
import AudioPlayer from '../audio-player/audio-player';
import { MDXRemoteSerializeResult } from 'next-mdx-remote';
import MDXRemoteView from '../mdx-remote-view/mdx-remote-view';
import { SlGlobe } from 'react-icons/sl';

export type DesignerCardProps = {
  name: string;
  description: MDXRemoteSerializeResult;
  photoUrl: string;
  audioTourUrl?: string;
  instagramUrl: string;
  websiteUrl?: string;
};

function DesignerCard({
  name,
  description,
  photoUrl,
  audioTourUrl,
  instagramUrl,
  websiteUrl,
}: DesignerCardProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.coverPhoto}>
        <Image
          alt=""
          layout="fill"
          objectFit="cover"
          src={photoUrl}
          loader={customLoader}
          unoptimized={true}
        />
        <div className={styles.links}>
          {instagramUrl && (
            <a
              className={styles.linkBtn + ' iconButton'}
              target="_blank"
              rel="noreferrer"
              href={instagramUrl}
            >
              <Icon icon={'icon-instagram'}></Icon>
            </a>
          )}
          {websiteUrl && (
            <a
              className={styles.linkBtn + ' iconButton'}
              target="_blank"
              rel="noreferrer"
              href={websiteUrl}
            >
              <SlGlobe />
            </a>
          )}
        </div>
      </div>
      <h5>Designed by</h5>
      <h3>{name}</h3>
      {audioTourUrl && (
        <div className={styles.audioPlayer}>
          <AudioPlayer src={audioTourUrl}></AudioPlayer>
        </div>
      )}
      {description && (
        <div className={styles.quote}>
          <MDXRemoteView {...description} />
        </div>
      )}
    </div>
  );
}

export default DesignerCard;
