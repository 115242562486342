import { CSSProperties, useState } from "react";
import { animated, useSpring, config, SpringValue } from "react-spring";
import styles from "./boundary-card.module.css";
import useMeasure from "react-use-measure";
import { useEffect } from "react";
import { CardState, SpringStyleObject } from "@/types";


export default function BoundaryCard({
    topOffsetForMini = 0,
    fitContent,
    stateOverride,
    showAction,
    headerActionBuilder,
    peakBuilder,
    miniBuilder,
    headerBuilder,
    contentBuilder,
    verticalAlignment,
    horizontalAlignment,
    actionLabel,
    onActionTapped,
    dismissOnAction,
    pageBuilder
}: any) {
    const [ref, { width, height }]: any = useMeasure();
    const [headerRef, headerSize]: any = useMeasure();
    const [contentRef, contentSize]: any = useMeasure();
    const [isMobile, setIsMobile]: any = useState(false);
    const [navigationStack, setNavigationStack] = useState([]);
    const states = {
        hidden: {
            width: (h: number) => (isMobile ? width - 60 : 330),
            height: (h: number, contentHeight: number, headerHeight: number) =>
                isMobile
                    ? 70 + "px"
                    : (fitContent ? contentHeight + headerHeight : 70) + "px",
            scale: () => 1.0,
            y: () => topOffsetForMini - 10.0,
            opacity: () => 0.0,
            [horizontalAlignment]: (w: number, h: number) => 30 + "px",
            [verticalAlignment]: (w: number, h: number) => 30 + "px",
            pointerEvents: () => "none",
            borderRadius: () => "0px",
            overflow: "hidden",
            scrollTop: "0px",
            config: { friction: 30, mass: 1, tension: 200 }
        },
        mini: {
            width: (h: number) => (isMobile ? width - 60 : 330),
            height: (h: number, contentHeight: number, headerHeight: number) => "70px",
            borderRadius: () => "0px",
            [horizontalAlignment]: (w: number, h: number) => 30 + "px",
            [verticalAlignment]: (w: number, h: number) => 30 + "px",
            scale: () => 1.0,
            y: () => topOffsetForMini,
            scrollTop: "0px",
            overflow: "hidden",
            opacity: () => 1.0,
            pointerEvents: () => "all",
            config: { friction: 30, mass: 1, tension: 200 }
        },
        peak: {
            width: (h: number) => 330,
            height: (h:number, contentHeight:number, headerHeight:number) =>
                fitContent ? "fit-content" : "200px",
            [horizontalAlignment]: (w:number, h: number) => 30 + "px",
            [verticalAlignment]: (w: number, h: number) => 30 + "px",
            borderRadius: () => "0px",
            scale: () => 1.0,
            y: () => 0.0,
            scrollTop: "0px",
            opacity: () => 1.0,
            pointerEvents: () => "all",
            overflow: "hidden",
            config: config.default
        },
        expanded: {
            width: (h: number) => (isMobile ? width : 330),
            height: (h: number, contentHeight: number, headerHeight:number) =>
                isMobile
                    ? h + "px"
                    : Math.min(contentHeight + headerHeight, h - 60) + "px",
            borderRadius: () => "0px",
            [horizontalAlignment]: (w: number, h: number) => (isMobile ? 0 : 30) + "px",
            [verticalAlignment]: (w: number, h: number) => (isMobile ? 0 : 30) + "px", //(h-Math.min(h*0.8,1000))/2 + "px",
            scale: () => 1.0,
            y: () => 0.0,
            overflow: "hidden",
            scrollTop: "0px",
            opacity: () => 1.0,
            pointerEvents: () => "all",
            config: { friction: 30, mass: 1, tension: 200 }
        }
    };

    const [state, setState] = useState<CardState>("hidden");

    //console.log(state);
    const spring: SpringStyleObject = useSpring({
        width: states[state].width(height),

        height: states[state].height(
            height,
            contentSize.height,
            headerSize.height
        ),
        [horizontalAlignment]: states[state][horizontalAlignment](
            width,
            height
        ),
        [verticalAlignment]: states[state][verticalAlignment](width, height),
        borderRadius: states[state].borderRadius(),
        scale: states[state].scale(),
        y: states[state].y(),
        opacity: states[state].opacity(),
        pointerEvents: states[state].pointerEvents(),
        maxWidth:
            isMobile && state == "expanded"
                ? "calc(100% - 0px)"
                : "calc(100% - 60px)",
        //overflow:states[state].overflow,
        config: states[state].config
    });

    const miniSpring: any = useSpring({
        opacity: state == "mini" ? 1 : 0,
        height: state == "mini" ? 70 : 0,
        y: state == "expanded" ? -70 : 0,
        pointerEvents: state == "mini" ? "all" : "none",
        config: config.default
    });
    const contentSpring: any = useSpring({
        opacity: state == "expanded" ? 1 : 0,
        y: state == "expanded" ? 0 : 0,
        scrollTop: 0,

        // overflow: "scroll",
        /*overflow: state == "expanded" &&
            contentSize.height + headerSize.height > height - 60
                ? "scroll"
                : "hidden",*/
        pointerEvents: state == "expanded" ? "all" : "none",
        config: config.default
    });

    const onMouseEnter = () => {
        //  if(state == "mini") setState("peak")
    };
    const onMouseLeave = () => {
        //if(state == "peak") setState("mini")
    };

    useEffect(() => {
        var newState = stateOverride ? stateOverride : "hidden";
        setState(newState);
    }, [stateOverride]);

    useEffect(() => {
        if (width == undefined || width == 0) return;
        //console.log("mobile eval card", width);
        setIsMobile(width < 500 && width != undefined);
    }, [width, height]);
    return (
        <div ref={ref} className={styles.BoundaryCard}>
            <animated.div
                className={styles.container}
                style={spring}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <animated.div style={miniSpring} className={styles.mini}>
                    {miniBuilder && miniBuilder()}
                </animated.div>
                {state == "peak" && (
                    <animated.div className={styles.peak}>
                        {peakBuilder && peakBuilder()}
                    </animated.div>
                )}
                <animated.div
                    ref={headerRef}
                    style={contentSpring}
                    className={styles.header}
                >
                    {headerBuilder && headerBuilder()}
                </animated.div>

                <animated.div
                    style={contentSpring}
                    scrollTop={contentSpring.scrollTop}
                    className={styles.content}
                >
                    <div ref={contentRef} className={styles.scroller}>
                        {contentBuilder && contentBuilder()}
                    </div>
                </animated.div>

                {showAction && (
                    <div className={styles.footer}>
                        <a className={"actionButton dark expanded"}>
                            {actionLabel}
                        </a>
                    </div>
                )}
            </animated.div>
        </div>
    );
}
