import styles from "./masonry.module.css";
import React from "react";

function Masonry({ itemBuilder, columnCount, itemCount }: any) {
    return (
        <div
            className={styles.wrapper}
            style={{ gridTemplateColumns: "repeat(" + columnCount + ",1fr)" }}
        >
            {[...new Array(columnCount)].map((value, columnIndex: any) => {
                //console.log("columnIndex",columnIndex)
                const itemColumnCount = Math.round(itemCount / columnCount);
                return (
                    <div key={columnIndex + ""} className={styles.column}>
                        {[...new Array(itemColumnCount)].map(
                            (value2, itemIndex: any) => {
                                var convertedIndex =
                                    itemIndex * columnCount + columnIndex;
                                if (convertedIndex >= itemCount)
                                    return <div></div>;
                                return itemBuilder(parseInt(convertedIndex));
                            }
                        )}
                    </div>
                );
            })}
        </div>
    );
}

export default Masonry;
