import { Product } from '@/types';
import React, { ReactNode, createContext } from 'react';

export const ProductsContext = createContext<any>({
  query: null,
  get: null,
  getRandomId: null,
  getByHotspotId: null,
});

export function ProductsProvider({
  children,
  data,
}: {
  children: ReactNode;
  data: Product[];
}) {
  const query = (
    where: (el: Product, index: number) => Product[],
    orderBy: (a: Product, b: Product) => number,
    limit: number,
    offset: number,
  ) => {
    var result = data.filter(where).sort(orderBy).splice(offset, limit);
    console.log('products', offset, limit, result, data);
    return result;
  };
  const get = (id: number) => {
    return data.find((p) => p.id == id);
  };
  const getByHotspotId = (id: string) => {
    return data.find((p) => {
      var result = p.hotspotId.replaceAll(' ', '_');
      return result == id;
    });
  };
  const getRandomId = () => {
    var count = data.length - 1;
    var index = Math.round(count * Math.random());
    return data[index]['id'];
  };
  return (
    <ProductsContext.Provider
      value={{
        query,
        get,
        getRandomId,
        getByHotspotId,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}
