import Head from "next/head";
import React from "react";

export default function Metatags({meta}:any){
  return <Head>
       
          <link rel="icon" href={meta.favicon} />
          <title>{meta.title}</title>
<meta name="description" content={meta.desc} />
      <meta property="og:title" content={meta.title} key="title" />
      <meta property="og:description" content={meta.desc} key="description" />
      <meta property="og:image" content={meta.img} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="801" />
      <meta property="og:url" content={meta.url} />
      <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.desc} />
        <meta name="twitter:site" content="@sometwitterhandle" />
        <meta property="twitter:image" content={meta.img} />
     
      {meta.product &&  <meta property="og:type" content="product" />}
        <meta property="og:site_name" content="Iconic House" />
 {meta.product && <meta property="product:price:amount" content={meta.product.price} />}
 {meta.product && <meta property="product:price:currency" content={meta.product.currency} />}

          <script async defer src="//assets.pinterest.com/js/pinit.js"></script>

      </Head>;
   
}