import axios from 'axios';

const InternalServiceAxios = axios.create({
	baseURL: '/'
});

export class InternalServiceApi {
	public static async getTour(tourId: string, dataType: string) {
		const url = `/api/tours/${tourId}`;
		const result = await InternalServiceAxios.get(url, {
			params: {
				tourId: tourId,
				dataType: dataType
			}
		});
		return result.data;
	}

	public static async getTourOptions(tourId: string, dataType: string) {
		const url = `/api/tours/${tourId}/options/tour`;
		const result = await InternalServiceAxios.post(url, {
			params: {
				tourId: tourId,
				dataType: dataType
			}
		});
		return result.data;
	}
}
