import { FirebaseApp, initializeApp} from 'firebase/app';
import { Analytics, getAnalytics} from  'firebase/analytics' // If you need it
import { useEffect, useState } from 'react';

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  //databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};


export function useFirebase() {
  const [app, setApp ] = useState<FirebaseApp>();
  const [analytics, setAnalytics] = useState<Analytics>();

  useEffect(() => {

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    setApp(app);
    setAnalytics(analytics);

  }, [])

  return { firebase: app, analytics}
}