import { useRouter } from 'next/dist/client/router';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import CookieConsent from '../components/cookie_consent/cookie_consent';
import { firebaseConfig, useFirebase } from './firebase';
import { logEvent as GALogEvent, getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { CookiePreference } from '@/types';

export const AnalyticsContext = createContext<any>({
  logStartTour: null,
  logWarpToCamera: null,
  logGotoRoom: null,
  logSharePage: null,
  logShareProduct: null,
  logOpenProduct: null,
  logCloseProduct: null,
  logGotoExternalProductPage: null,

  trackingAllowed: false,
});

export function TrackingProvider({ children, cookieName }: any) {
  const router = useRouter();
  //   const { firebase, analytics } = useFirebase();
  const instanceRef: any = useRef();
  //const pixelRef: any = useRef();
  const [pixelAllowed, setPixelAllowed] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
  const [cookiePreferences, setCookiePreferences] = useState<CookiePreference>({
    essential: true,
    advertising: false,
  });
  const [showConsent, setShowConsent] = useState(false);

  const logEvent = async (event_name: string, data: object) => {
    console.log('[GA] track event request', instanceRef.current);
    if (instanceRef.current) {
      var result = await GALogEvent(instanceRef.current, event_name, {
        ...data,
        debug_mode: process.env.NEXT_PUBLIC_GA_ANALYTICS_DEBUG_MODE === 'true',
      });

      console.log(
        '[GA] logged an event',
        event_name,
        data,
        instanceRef.current,
        result,
      );
    }
  };

  const logStartTour = () => {
    logEvent('start_tour', {});
  };

  const logWarpToCamera = (
    camera_id: string,
    room_id: string,
    tour_url: string,
  ) => {
    logEvent('warp_to_camera', {
      camera: String(camera_id),
      room: room_id,
      tour: tour_url,
    });
  };

  const logGotoRoom = (
    camera_id: string,
    room_id: string,
    tour_url: string,
  ) => {
    logEvent('go_to_room', {
      room: room_id,
      camera: String(camera_id),
      tour: tour_url,
    });
  };

  const logOpenProduct = (
    productId: number,
    product_name: string,
    room: string,
    tour_url: string,
  ) => {
    logEvent('open_product', {
      product: productId,
      product_name,
      room,
      tour: tour_url,
    });

    logEvent('view_item', {
      items: [
        {
          item_id: productId,
          item_name: product_name,
          location_id: room,
        },
      ],
    });
  };
  const logCloseProduct = (
    productId: number,
    product_name: string,
    room: string,
    tour_url: string,
  ) => {
    logEvent('close_product', {
      product: productId,
      product_name,
      room,
      tour: tour_url,
    });
  };

  const logGotoExternalProductPage = (
    productId: number,
    product_name: string,
    room: string,
    external_url: string,
    tour_url: string,
  ) => {
    logEvent('go_to_external_product_page', {
      product: productId,
      product_name,
      room,
      external_url,
      tour: tour_url,
    });
  };

  useEffect(() => {
    var cookie = cookies[cookieName];
    console.log('cookie', cookie);
    if (!cookie) {
      setShowConsent(true);
    } else {
      reload(cookie);
    }
  }, [cookies, cookieName]);
  const onConsentChange = (preferences: CookiePreference) => {
    console.log(cookieName, preferences);
    var d: Date = new Date(Date.now());
    d = new Date(d.setTime(d.getTime() + 60 * 60 * 1000 * 24 * 28));
    console.log(d);

    setCookie(cookieName, preferences, {
      expires: d,
      sameSite: 'none',
      secure: true,
    });
    setCookiePreferences(preferences);
    reload(preferences);
  };

  const reload = async (preferences: CookiePreference) => {
    console.log('reload', preferences);
    if (preferences && !instanceRef.current && preferences.advertising) {
      try {
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
        const ga: any = analytics;
        //console.log("ga",ga.app.options.measurementId);
        (window as any).gtag('set', { send_page_view: false });
        (window as any).gtag('config', ga.app.options.measurementId, {
          cookie_flags: 'max-age=7200;secure;samesite=none',
        });
        (window as any).gtag('event', 'page_view');

        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };
        //setPixelAllowed(true);
        console.log('[GA] ga instantiated', ga);
        instanceRef.current = ga;
      } catch (err) {
        console.log('[GA] Error initializing GA', err);
      }
    }
  };

  return (
    <AnalyticsContext.Provider
      value={{
        logStartTour,

        logWarpToCamera,
        logGotoRoom,

        logOpenProduct,
        logCloseProduct,

        logGotoExternalProductPage,
      }}
    >
      {children}
      <CookieConsent
        cookieName={cookieName}
        preferences={cookiePreferences}
        onConsentChange={onConsentChange}
        open={showConsent}
      ></CookieConsent>
    </AnalyticsContext.Provider>
  );
}
