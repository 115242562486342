import Image from 'next/image';
import React, { useMemo, useState } from 'react';
import { DeviceContextProvider } from '@/contexts/device.context';
import {
  AnnotationDetails,
  GroupDetails,
  ICameraDetails,
} from '@vrpm/viewform-contracts';
import _ from 'lodash';

import styles from '../styles/Home.module.css';

import BoundaryExplorerIntroComponent from '../components/boundary-explorer-intro/boundary-explorer-intro.component';
import Icon from '../components/icon/icon';
import { TrackingProvider } from '../lib/analytics_provider';

import Metatags from '../components/metatags/metatags';
import { StateContext, StateProvider } from '../lib/state_provider';
import { ProductsProvider } from '../lib/products_provider';
import RoomCard from '../components/room-card/room-card';
import ProductCard from '../components/product-card/product-card';
import ImageOverlayComponent from '../components/image-overlay/image-overlay';

import { fetchAPI } from '../lib/api';
import { CookiesProvider } from 'react-cookie';
import { serialize } from 'next-mdx-remote/serialize';
import {
  AppState,
  Camera,
  ExteriorCamera,
  Marker,
  Navigation,
  Product,
  Room,
  Site,
  Sponsor,
  ViewformConfig,
} from '@/types';
import { useViewform } from '@vrpm/viewform';
import { useTourFetch } from '@/hooks/useTourFetch';
import Viewform from '@/components/tour/viewform';
import { useRouter } from 'next/router';
import LandingScreen from '@/components/landing-screen-ic3/landing-screen-ic3';
import { getGroupsFromTour } from '@/utils/viewform-utils';
import NavigationUI from '@/components/navigation-ic3/navigation-ic3';

const DEFAULT_CMS_DATA = {
  site: {
    favicon: {
      url: '',
    },
  } as Site,
  products: [],
  rooms: [],
  cameras: [],
  markers: [],
  sponsors: [],
  viewformConfig: {},
  navigations: [],
  exteriorCameras: [],
};

export async function getStaticProps() {
  const useStaticProps = process.env.NEXT_PUBLIC_STATIC_PROPS || 'true';

  if (useStaticProps === 'true') {
    const site = await fetchAPI('/site');
    const viewformConfig = await fetchAPI('/viewform-config');
    const products = await fetchAPI('/products?versionTag_eq=IC3&_limit=200');
    const rooms = await fetchAPI('/rooms?versionTag_eq=IC3');
    const markers = await fetchAPI('/markers?versionTag_eq=IC3');
    const cameras = await fetchAPI('/cameras?versionTag_eq=IC3&_limit=200');
    const exteriorCameras = await fetchAPI(
      '/exterior-cameras?versionTag_eq=IC3&_limit=200&_sort=order:ASC',
    );
    const navigations = await fetchAPI('/navigations?versionTag_eq=IC3&_sort=id:ASC');
    for (var i = 0; i < rooms.length; i++) {
      var room = rooms[i];

      for (var j = 0; j < room.designers.length; j++) {
        var d = room.designers[j];
        d.serializedDescription = await serialize(d.quote);
      }
    }
    for (var i = 0; i < products.length; i++) {
      var d = products[i];
      d.serializedDescription = await serialize(d.description);
    }

    return {
      props: {
        site,
        products,
        rooms,
        cameras,
        markers,
        viewformConfig,
        navigations,
        exteriorCameras,
      }, // will be passed to the page component as props
    };
  } else {
    return {
      props: DEFAULT_CMS_DATA,
    };
  }
}

export type CMSData = {
  site: Site;
  products: Product[];
  rooms: Room[];
  sponsors: Sponsor[];
  cameras: Camera[];
  markers: Marker[];
  viewformConfig: ViewformConfig;
  navigations: Navigation[];
  exteriorCameras: ExteriorCamera[];
};

export type HomeProps = CMSData;

export default function Home({
  site,
  products,
  rooms,
  exteriorCameras,
  cameras,
  markers,
  viewformConfig,
  navigations,
}: HomeProps) {
  const router = useRouter();
  const { room = '' } = router.query;

  const goToCamera = useViewform((s) => s.goToCamera);
  const setImageVersionById = useViewform((s) => s.setImageVersionById);
  const { tour, tourOptions } = useTourFetch();

  // NOTE: if room param exist, skip landing screen and start from that room directly
  const [showLandingScreen, setShowLandingScreen] = useState<boolean>(true);

  const [navStartCameraId, setNavStartCameraId] = useState<string>();
  const defaultStartCameraId =
    viewformConfig.startCamera?.viewformCameraId || '';
  const roomStartCameraId = useMemo(() => {
    const startRoom = _.chain(rooms)
      .map((r: Room) => ({ ...r, roomId: _.toLower(r.roomId) }))
      .find({ roomId: _.toLower(room as string) })
      .value();
    const startCameraId = _.isEmpty(startRoom)
      ? ''
      : startRoom?.startCamera?.viewformCameraId;
    return startCameraId;
  }, [room, rooms]);

  // NOTE: This is ordered by priority.
  const startCameraId =
    roomStartCameraId || navStartCameraId || defaultStartCameraId;

  const [activeExteriorGroups, setActiveExteriorGroups] =
    useState<GroupDetails[]>();
  const exteriorCameraIds = exteriorCameras.map(
    (c) => c.viewformCameraId || '',
  );
  const hotspotGroups = useMemo(() => getGroupsFromTour(tour), [tour]);
  const getActiveExteriorGroups = (floor: number) => {
    const roomIds = _.chain(rooms)
      .filter((r) => r.floor === floor)
      .map((r) => r.roomId)
      .value();

    return hotspotGroups.filter((g) => _.includes(roomIds, g.name));
  };

  const handleLandingNavigate = (startCamera?: Camera) => {
    setNavStartCameraId(startCamera?.viewformCameraId || defaultStartCameraId);
    setShowLandingScreen(false);
  };
  const handleLoadingEnd = () => {
    setShowLandingScreen(false);
  };
  const handleNavClick = (camera: ExteriorCamera) => {
    if (camera.viewformCameraId && camera.viewformImageVersionId) {
      setImageVersionById(camera.viewformImageVersionId);
      goToCamera(camera.viewformCameraId);

      if (_.isNumber(camera?.floor) && camera.floor > 0) {
        setActiveExteriorGroups(getActiveExteriorGroups(camera.floor));
      } else {
        setActiveExteriorGroups([]);
      }
    }
  };

  const tourLoaded = tour && tour.sasInfo && tourOptions;

  console.log({ tour});

  return (
    <div className={styles.container}>
      <CookiesProvider>
        <TrackingProvider cookieName={'boundarycookieconsent'}>
          <ProductsProvider data={products}>
            <StateProvider
              site={site}
              cameras={cameras}
              markers={markers}
              roomData={rooms}
              viewformConfig={viewformConfig}
              tour={tour}
            >
              <DeviceContextProvider>
                <StateContext.Consumer>
                  {(bs: AppState) => (
                    <>
                      <Metatags meta={bs.meta}></Metatags>
                      {tourLoaded && !showLandingScreen && (
                        <main className={styles.main}>
                          <Viewform
                            tour={tour}
                            groupSphereColor={
                              viewformConfig.groupSphereColor || ''
                            }
                            activeGroups={activeExteriorGroups || []}
                            exteriorCameras={exteriorCameraIds}
                            startCameraId={startCameraId}
                            tourOptions={tourOptions}
                            onTagClick={(tag: AnnotationDetails) => {
                              bs.openProduct(
                                (tag.name || '').replaceAll(' ', '_'),
                                true,
                              );
                            }}
                            onGroupChanged={(groupName: string) => {
                              bs.updateRoom(groupName);
                            }}
                            onGroupSphereClicked={(roomId: string) => {
                              const camera = bs.getRoomStartCamera(roomId);
                              if (
                                !_.isEmpty(camera) &&
                                camera.viewformCameraId
                              ) {
                                console.log('Group: Going to camera', camera);
                                goToCamera(camera.viewformCameraId);
                              }
                            }}
                            onJumpEnd={(camera: ICameraDetails) => {
                              bs.onCameraWarp(camera.id);
                            }}
                            onRotationStart={() => {
                              bs.incrementInteractionCount();
                            }}
                          />
                        </main>
                      )}

                      <div className={styles.footer}>
                        <NavigationUI
                          navigationCameras={exteriorCameras}
                          onNavClick={handleNavClick}
                        />
                        <div className="ml-[1px]">
                          <button
                            className={
                              'text-black px-4 h-[50px]  bg-white cursor-pointer pointer-events-auto'
                            }
                            style={{}}
                            onClick={() => {
                              bs.openIntro(); // Open guide popup
                            }}
                          >
                            <Icon icon={'icon-info'}></Icon>
                          </button>
                        </div>
                      </div>
                      <a
                        className={styles.boundaryLogo}
                        href="https://www.the-boundary.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          alt="The Boundary"
                          src={'boundary_logo.svg'}
                          loader={(attr) => attr.src}
                          unoptimized={true}
                          layout={'fill'}
                          objectFit={'contain'}
                        />
                      </a>
                      {bs.rooms &&
                        bs.rooms.map((room, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                zIndex: 100 + (bs.rooms.length - index),
                              }}
                            >
                              <RoomCard room={room} index={index}></RoomCard>
                            </div>
                          );
                        })}

                      {bs.product && (
                        <ProductCard
                          productId={bs.product.id}
                          open={bs.showProductCard}
                        ></ProductCard>
                      )}
                      {bs.gallery && (
                        <ImageOverlayComponent
                          media={bs.gallery}
                          current={bs.galleryStartIndex}
                          show={bs.showImageOverlay}
                        ></ImageOverlayComponent>
                      )}
                      {bs.showBoundaryExplorerIntro && (
                        <BoundaryExplorerIntroComponent
                          open={bs.showBoundaryExplorerIntro}
                          logo={'/logo.svg'}
                          invert
                          onDone={bs.onIntroDone}
                        ></BoundaryExplorerIntroComponent>
                      )}

                      {showLandingScreen && (
                        <LandingScreen
                          navigations={navigations}
                          onNavigate={handleLandingNavigate}
                          tourLoaded={!!tourLoaded}
                          useNavigation={room.length === 0}
                          onLoadingEnd={handleLoadingEnd}
                        />
                      )}
                    </>
                  )}
                </StateContext.Consumer>
              </DeviceContextProvider>
            </StateProvider>
          </ProductsProvider>
        </TrackingProvider>
      </CookiesProvider>
    </div>
  );
}
