import React, { useContext, useEffect, useState, useRef } from "react";
import { ProductsContext } from "../../lib/products_provider";
import { StateContext } from "../../lib/state_provider";
import BoundaryCard from "../boundary-card/boundary-card";
import ProductDetailView from "../product-detail-view/product-detail-view";
import ProductHeader from "../product-header/product-header";
import styles from "./product-card.module.css";
import { Product } from "@/types";

export default function ProductCard({ productId, ctaLabel, open }: any) {
    const products = useContext(ProductsContext);
    const bs = useContext(StateContext);
    var [product, setProduct] = useState<Product>();
    var [cardState, setCardState] = useState("hidden");
    var timeoutRef: any = useRef();

    useEffect(() => {
        console.log("new product state", open, productId);

        if (productId && open) {
            console.log("opening the product up");
            //if product already exists we should close than open again
            setCardState("hidden");
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(
                () => {
                    setCardState("expanded");
                    var result = products.get(productId);

                    console.log("products", result);
                    setProduct(result);
                },
                !product ? 0 : 500
            );
        } else {
            setCardState("hidden");
            return;
        }
    }, [productId, open,products, product]);

    return (
        <BoundaryCard
            fitContent={true}
            horizontalAlignment={"right"}
            verticalAlignment={"top"}
            actionLabel={"LEARN MORE"}
            stateOverride={cardState}
            headerBuilder={() => {
                return (
                    product && (
                        <ProductHeader
                            name={product.title}
                            sponsors={product.shopName}
                            floorName={""}
                            onCloseClicked={() => {
                                bs.closeProduct(true);
                                bs.toggleRoomCards(false, "mini");
                                // onAction("onClose");
                            }}
                        ></ProductHeader>
                    )
                );
            }}
            contentBuilder={() => {
                return (
                    <div className={styles.productCard}>
                        {product && (
                            <ProductDetailView
                                onClick={() => {
                                    bs.clickedExternalProductLink(product);
                                }}
                                name={product.title}
                                maker={product.shopName}
                                description={product.serializedDescription}
                                media={product.media}
                                ctaUrl={product.externalLink}
                                ctaLabel={product.ctaLabel}
                            ></ProductDetailView>
                        )}
                    </div>
                );
            }}
        ></BoundaryCard>
    );
}
