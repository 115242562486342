import styles from "./image-overlay.module.css";
import { useSpring, animated } from "react-spring";
import Image from "next/image";
import customLoader from "../custom-loader";
import React, { useEffect, useContext } from "react";
import PageViewComponent from "../page-view/page-view";
import { StateContext } from "../../lib/state_provider";
import Icon from "../icon/icon";
import { Media } from "@/types";

export type ImageOverlayComponentProps = {
    media:Media[];
    current: number;
    show: boolean;
    onDone?: () => void;
}

function ImageOverlayComponent({ media, current, show, onDone }: ImageOverlayComponentProps) {
    const bs = useContext(StateContext);
    const spring: any = useSpring({
        opacity: show ? 1 : 0,
        pointerEvents: show ? "all" : "none"
    });

    return (
        <animated.div className={styles.ImageOverlayComponent} style={spring}>
            <div className={styles.card}>
                <PageViewComponent
                    count={media.length}
                    current={current}
                    builder={(i: number) => {
                        const data = media[i];
                        const fallbackUrl  = data?.url || '';

                        return (
                            <Image
                                alt=""
                                layout="fill"
                                objectFit="contain"
                                src={data.formats?.large?.url || fallbackUrl}
                                placeholder="blur"
                                blurDataURL={data.formats?.small?.url ||fallbackUrl}
                                loader={customLoader}
                                unoptimized={true}
                            />
                        );
                    }}
                    prevBuilder={() => {
                        return (
                            <button className={"iconButton"}>
                                <Icon icon={"icon-chevron-left"}></Icon>
                            </button>
                        );
                    }}
                    nextBuilder={() => {
                        return (
                            <button className={"iconButton"}>
                                <Icon icon={"icon-chevron-right"}></Icon>
                            </button>
                        );
                    }}
                ></PageViewComponent>
                <button
                    className={styles.closeButton + " iconButton"}
                    onClick={() => {
                        bs.closeImageGallery();
                    }}
                >
                    <Icon icon={"icon-close"}></Icon>
                </button>
            </div>
        </animated.div>
    );
}
export default ImageOverlayComponent;
