import styles from './room-header-peak.module.css';
import { useContext, useEffect } from 'react';
import React from 'react';
import Avatar from '../avatar/avatar';
import { useRive, useStateMachineInput } from 'rive-react';
import { StateContext } from '../../lib/state_provider';
import { Media, Sponsor } from '@/types';

export type RoomHeaderPeakProps = {
  name: string;
  sponsors: Sponsor[];
  floorName: string;
  avatarUrl: Media;
  onClick: () => void;
}
function RoomHeaderPeak({
  name,
  sponsors,
  floorName,
  avatarUrl,
  onClick,
}: any) {
  const bs = useContext(StateContext);
  const STATE_MACHINE_NAME = 'sm';
  const INPUT_PLAYING_NAME = 'playing';
  const INPUT_HOVER_NAME = 'hover';

  const { RiveComponent, rive } = useRive({
    src: 'animations/audioripples.riv',
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  const onPlayingInput = useStateMachineInput(
    rive,
    STATE_MACHINE_NAME,
    INPUT_PLAYING_NAME,
  );
  const onHoverInput = useStateMachineInput(
    rive,
    STATE_MACHINE_NAME,
    INPUT_HOVER_NAME,
  );

  useEffect(() => {
    if (!onPlayingInput) return;
    onPlayingInput.value = bs.playingAudio;
  }, [bs.playingAudio, onPlayingInput]);

  return (
    <div className={styles.wrapper + ' button'} onClick={onClick}>
      <Avatar media={avatarUrl} />
      <div className={styles.text}>
        <h6>
          <b>{name}</b>
        </h6>
        <p>{sponsors}</p>
      </div>
      <div
        className={styles.audioPlaying}
        onClick={(e) => {
          e.stopPropagation();
          bs.toggleAudioPlaying(!bs.playingAudio);
          if (onPlayingInput) {
            onPlayingInput.value = !onPlayingInput.value;
          }
        }}
        onMouseEnter={() => {
          if (onHoverInput) {
            onHoverInput.value = true;
          }
        }}
        onMouseLeave={() => {
          if (onHoverInput) {
            onHoverInput.value = false;
          }
        }}
      >
        <RiveComponent></RiveComponent>
      </div>
    </div>
  );
}

export default RoomHeaderPeak;
