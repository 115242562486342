import styles from "./product-detail-view.module.css";
import customLoader from "../custom-loader";
import Image from "next/image";
import { useContext, useState } from "react";
import React from "react";
import Icon from "../icon/icon";
import PageViewComponent from "../page-view/page-view";
import { StateContext } from "../../lib/state_provider";
import { MDXRemoteSerializeResult } from "next-mdx-remote";
import { Media } from "@/types";
import MDXRemoteView from "../mdx-remote-view/mdx-remote-view";

export type ProductDetailViewProps = {
    name?: string;
    maker?: string;
    description: MDXRemoteSerializeResult;
    media: Media[];
    ctaUrl: string;
    onClick:() => void;
    ctaLabel?: string;
}
function ProductDetailView({
    description,
    media,
    ctaUrl,
    onClick,
    ctaLabel
}: ProductDetailViewProps) {
    const bs = useContext(StateContext);
    var [currentIndex, setCurrentIndex] = useState<number>(0);
    return (
        <div className={styles.wrapper}>
            <div className={styles.coverPhoto}>
                <PageViewComponent
                    lightMode={true}
                    onCurrentChange={(current: number) => {
                        setCurrentIndex(current);
                    }}
                    count={media.length}
                    builder={(i: number) => {
                        const data = media[i];
                        const fallbackUrl = data?.url || '';
                        return (
                            <div>
                                <Image
                                    alt=""
                                    layout="fill"
                                    objectFit="cover"
                                    placeholder="blur"
                                    blurDataURL={data.formats?.small?.url || fallbackUrl}
                                    src={data.formats?.medium?.url || fallbackUrl}
                                    loader={customLoader}
                                    unoptimized={true}
                                />
                            </div>
                        );
                    }}
                ></PageViewComponent>

                <button
                    className={styles.expand + " iconButton"}
                    onClick={() => {
                        bs.openImageGallery(currentIndex, media);
                    }}
                >
                    <Icon icon={"icon-magnifying-glass-plus"}></Icon>
                </button>
                <div className={styles.share}></div>
            </div>

            <a
                onClick={onClick}
                className={styles.action + " actionButton dark"}
                href={ctaUrl}
                rel={"noreferrer"}
                target={"_blank"}
            >
                {ctaLabel ?? 'PURCHASE'}
            </a>
            <div className={styles.description}>
                <MDXRemoteView {...description} />
            </div>
        </div>
    );
}

export default ProductDetailView;
