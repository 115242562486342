import styles from "./cookie_consent.module.css";
import { animated, useTransition } from "react-spring";
import React, { ChangeEvent, useState } from "react";
import { useEffect } from "react";
import Toggle from "react-toggle";
import { SpringStyleObject } from "@/types";

function CookieConsent({ onConsentChange, open, preferences }: any) {
    const [show, setShow] = useState(true);
    const [edit, setEdit] = useState(false);
    const [cookiePreferences, setCookiePreferences] = useState({
        essential: true,
        advertising: true
    });
    const transitions: any = useTransition(show, {
        from: {
            opacity: 0,
            pointerEvents: "none"
        },

        enter: {
            opacity: 1,
            pointerEvents: "all"
        },
        leave: {
            opacity: 0,
            pointerEvents: "none"
        },
        reverse: show
    });

    useEffect(() => {
        //read the cookie that stores consent and show consent bar if it is not set yet

        setShow(open);
    }, [open]);
    useEffect(() => {
        setCookiePreferences(preferences);
    }, [preferences]);
    const onPreferenceChange = (preferenceName: string, e: ChangeEvent ) => {
        console.log(preferenceName, e);
        setCookiePreferences({
            ...cookiePreferences,
            [preferenceName]: (e.target  as HTMLInputElement).checked
        });
    };

    const onAcceptAll = () => {
        onConsentChange({ ...cookiePreferences, advertising: true });
        setShow(false);
    };
    const onDone = () => {
        //save settings and reload tracker
        onConsentChange(cookiePreferences);
        setShow(false);
    };
    return transitions(
        (spring: SpringStyleObject, item: unknown) =>
            item && (
                <animated.div className={styles.CookieConsent} style={spring}>
                    <div
                        className={styles.background}
                        data-test-id="cookieconsentbackground"
                        onClick={onAcceptAll}
                    ></div>
                    <animated.div className={styles.card}>
                        <h4>We use Cookies</h4>

                        <p>
                            For the best experience, we use cookies and similar
                            tools for performance, analytics, personalisation
                            and advertising. Clear your cookies to reset your
                            preferences.
                            <br />
                        </p>
                        <p>
                            You can also accept all by clicking anywhere outside
                            this popup.
                        </p>
                        {edit && (
                            <div>
                                <div className={styles.customizeCategory}>
                                    <div className={styles.label}>
                                        {" "}
                                        <h5>Required Cookies & Technologies</h5>
                                        <p>
                                            These cookies are essential for us
                                            to remember your privacy settings.
                                        </p>
                                    </div>

                                    <Toggle
                                        defaultChecked={
                                            cookiePreferences.essential
                                        }
                                        disabled={true}
                                    />
                                </div>

                                <div className={styles.customizeCategory}>
                                    <div className={styles.label}>
                                        {" "}
                                        <h5>Google Analytics</h5>
                                        <p>
                                            These cookies helps us understand
                                            how the site is being used and how
                                            we can improve it.
                                        </p>
                                    </div>
                                    <div data-test-id="cookie-consent-marketing-toggle">
                                        <Toggle
                                            defaultChecked={
                                                cookiePreferences.advertising
                                            }
                                            onChange={(e) =>
                                                onPreferenceChange(
                                                    "advertising",
                                                    e
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className={styles.actionBar}>
                            {!edit && (
                                <>
                                    <button
                                        data-test-id="cookie-consent-customize-button"
                                        className={
                                            styles.rejectAction +
                                            " actionButton"
                                        }
                                        onClick={() => {
                                            setEdit(true);
                                        }}
                                    >
                                        CUSTOMIZE
                                    </button>
                                    <button
                                        data-test-id="cookie-consent-all-button"
                                        className={
                                            styles.acceptAction +
                                            " actionButton"
                                        }
                                        onClick={onAcceptAll}
                                    >
                                        ACCEPT ALL
                                    </button>
                                </>
                            )}
                            {edit && (
                                <button
                                    data-test-id="cookie-consent-done-button"
                                    className={
                                        styles.acceptAction + " actionButton"
                                    }
                                    onClick={onDone}
                                >
                                    DONE
                                </button>
                            )}
                        </div>
                    </animated.div>
                </animated.div>
            )
    );
}
export default CookieConsent;
