import React, { useContext, useMemo } from "react";
import { StateContext } from "../../lib/state_provider";
import BoundaryCard from "../boundary-card/boundary-card";
import DesignerCard from "../designer-card/designer-card";
import Masonry from "../masonry/masonry";
import ProductListItem from "../product-list-item/product-list-item";
import RoomHeaderMini from "../room-header-mini/room-header-mini";
import RoomHeaderPeak from "../room-header-peak/room-header-peak";
import RoomHeader from "../room-header/room-header";
import { Designer, Media, Product, Room } from "@/types";

export type RoomCardProps = {
    room: Room;
    onAction?: () => void;
    index: number;
}

type StateDetails = {
    designer: Designer;
    designerProducts: Product[];
    thumbnail: Media;
    cover: string;
    sponsorText: string;
}

export default function RoomCard({ room, onAction, index }: RoomCardProps) {
    const bs = useContext(StateContext);

    const { designer, designerProducts, cover, thumbnail, sponsorText} = useMemo((): StateDetails => {
        const designer = room.designers[0];
        const designerProducts = room.products.filter(
            (thing: Product, index: number, self: Product[]) =>
                index === self.findIndex((t) => t.title === thing.title)
        );
        const thumbnail = designer.avatar;
        const cover = designer.cover.formats?.medium?.url || designer.cover?.url || '';

        // NOTE: Oct 2023, No sponsor this year. Showing designer instead of sponsor information
        // Sponsored by XXX => Designed by XXX
        // const sponsorText = room.sponsor ? room.sponsor.sponsoredByLabel : "";
        const sponsorText = designer && designer.name ? `Designed by ${designer.name}` : '';

        return {
            designer,
            designerProducts,
            thumbnail,
            cover,
            sponsorText
        }

    }, [room]);

    if (!room) return <div></div>;

    return (
        <BoundaryCard
            horizontalAlignment={"right"}
            verticalAlignment={"top"}
            actionLabel={"explorer"}
            topOffsetForMini={index * 70}
            dismissOnAction
            stateOverride={bs.roomCardStates[index]}
            miniBuilder={() => {
                return (
                    <RoomHeaderMini
                        name={room.name}
                        sponsors={sponsorText}
                        floorName={
                            room.floor == 1 ? "First Floor" : "Second Floor"
                        }
                        avatar={thumbnail}
                        isPlaying={
                            designer &&
                            bs.playingAudio &&
                            designer.audio &&
                            designer.audio.url == bs.playingAudio
                        }
                        onClick={() => {
                            bs.toggleRoomCard(index, true);
                        }}
                    ></RoomHeaderMini>
                );
            }}
            peakBuilder={() => {
                return (
                    <RoomHeaderPeak
                        name={room.name}
                        sponsors={sponsorText}
                        floorName={
                            room.floor == 1 ? "First Floor" : "Second Floor"
                        }
                        avatarUrl={thumbnail}
                        onClick={() => {
                            bs.toggleRoomCards(index, true);
                        }}
                    ></RoomHeaderPeak>
                );
            }}
            headerBuilder={() => {
                return (
                    <RoomHeader
                        name={room.name}
                        sponsors={sponsorText}
                        floorName={
                            room.floor == 1 ? "First Floor" : "Second Floor"
                        }
                        onCloseClicked={() => {
                            bs.toggleRoomCards(false, "mini");
                            // onAction("onClose");
                        }}
                        onFloorplanClicked={() => {
                            bs.openMap();
                            //onAction("openFloorplan");
                        }}
                    ></RoomHeader>
                );
            }}
            contentBuilder={() => {
                return (
                    <div>
                        {designer && (
                            <DesignerCard
                                name={designer.name}
                                description={designer.serializedDescription}
                                photoUrl={cover || ''}
                                instagramUrl={designer.instagram}
                                audioTourUrl={designer.audio?.url}
                                websiteUrl={designer.website}
                            ></DesignerCard>
                        )}

                        {designerProducts.length > 0 && (
                            <>
                                <h5>DESIGNER&apos;S INSPIRATION</h5>
                                <div className={"spacer"}></div>
                            </>
                        )}

                        {bs.roomCardStates[index] == "expanded" &&
                            designerProducts &&
                            designerProducts.length > 0 && (
                                <Masonry
                                    columnCount={1}
                                    itemCount={designerProducts.length}
                                    itemBuilder={(i: number) => {
                                        const product = designerProducts[i];
                                        const media = product?.media[0] || {};
                                        const photoUrl = media.formats?.medium?.url || media?.url || '';

                                        return (
                                            <ProductListItem
                                                key={i}
                                                name={product.title}
                                                shopName={product.shopName}
                                                photoUrl={photoUrl}
                                                onClick={() => {
                                                    bs.clickedExternalProductLink(
                                                        product
                                                    );
                                                }}
                                                ctaUrl={product.externalLink}
                                                ctaLabel={product.ctaLabel}
                                            ></ProductListItem>
                                        );
                                    }}
                                ></Masonry>
                            )}
                    </div>
                );
            }}
        ></BoundaryCard>
    );
}
