import styles from "./boundary-explorer-intro.module.css";
import { animated, useTransition } from "react-spring";
import Image from "next/image";

import Icon from "../icon/icon";
import React, { useEffect, useState } from "react";
import { SpringStyleObject } from "@/types";
function BoundaryExplorerIntroComponent({
    transitionDuration,
    onDone,
    open,
    title,
    subtitle,
    logo,
    invert
}: any) {
    const [show, setShow] = useState(true);
    const transitions: any = useTransition(show, {
        from: {
            opacity: 1
        },
        to: {
            opacity: 1
        },
        leave: {
            opacity: 0
        },
        reverse: show,
        duration: transitionDuration
    });
    useEffect(() => {
        setShow(open);
    }, [open]);

    return transitions(
        (spring: SpringStyleObject, item: unknown) =>
            item && (
                <animated.div
                    className={styles.BoundaryExplorerIntroComponent}
                    style={spring}
                >
                    <div className={styles.card}>
                        {logo && (
                            <div
                                className={styles.cardLogo}
                                style={{ filter: !invert ? "" : "invert(1)" }}
                            >
                                <Image
                                    alt=""
                                    layout="fill"
                                    objectFit="contain"
                                    src={logo}
                                    loader={(e) => e.src}
                                    unoptimized={true}
                                />
                            </div>
                        )}
                        {title && <h2 className={styles.cardTitle}>{title}</h2>}
                        {subtitle && (
                            <p className={styles.cardSubtitle}>{subtitle}</p>
                        )}
                        <div className={styles.cardGrid}>
                            <div className={styles.cardItem}>
                                <Icon
                                    icon={"icon-mouse-button-left"}
                                    className={styles.image}
                                />
                                <p>Left click/tap to move forward</p>
                            </div>

                            <div className={styles.cardItem}>
                                <Icon
                                    icon={"icon-directions"}
                                    className={styles.image}
                                />
                                <p>Click and drag to look around</p>
                            </div>
                            <div className={styles.cardItem}>
                                <Icon
                                    icon={"icon-directional-buttons"}
                                    className={styles.image}
                                />
                                <p>Alternatively use arrow keys</p>
                            </div>
                            <div className={styles.cardItem}>
                                <Icon
                                    icon={"icon-tap-hotspot"}
                                    className={styles.image}
                                />
                                <p>Click on hotspots for more info</p>
                            </div>
                            {/* <div className={styles.cardItem}>
                                <Icon
                                    icon={"icon-layers"}
                                    className={styles.image}
                                />
                                <p>Click this symbol to see the floorplan</p>
                            </div> */}
                        </div>
                        <button
                            onClick={() => {
                                onDone && onDone();
                            }}
                            className={styles.cardAction + " actionButton expanded" + " text-black"}
                        >
                            got it
                        </button>
                    </div>
                </animated.div>
            )
    );
}
export default BoundaryExplorerIntroComponent;
