import { Html, Box } from '@react-three/drei';
import React, { useContext, useState, useMemo } from 'react';
import { DoubleSide, Vector3, Box3 } from 'three';
import {
  CameraDetails,
  GroupDetails,
  ViewformTour,
} from '@vrpm/viewform-contracts';
import { AnnotationDetails } from '@vrpm/viewform-contracts';
import { useSpring } from '@react-spring/core';
import { a, animated } from '@react-spring/three';
import { StateContext } from '@/lib/state_provider';
import _ from 'lodash';
import GroupHotspot from './group-hotspot';
import { Marker, Room } from '@/types';
import {
  DeviceContextProvider,
  useDeviceContext,
} from '@/contexts/device.context';

interface GroupSelectionProps {
  cameras: CameraDetails[];
  tags: AnnotationDetails[];
  name: string;
  onGroupSphereClicked: (name: string) => void;
  group: GroupDetails;
  tour: ViewformTour;
  alwaysShowBoundingBox: boolean;
  showBoundingBoxOnHover: boolean;
  groupSphereColor: string;
}

export const Group = (props: GroupSelectionProps) => {
  const cameraPoints = useMemo(
    () =>
      props.cameras.reduce((values, { position: { x, y, z } }) => {
        const points: any = [
          new Vector3(x - 0.5, y - 0.5, z - 0.5),
          new Vector3(x + 0.5, y + 0.5, z + 0.5),
        ];
        values = values.concat(points);
        return values as Vector3[];
      }, [] as Vector3[]),
    [props.cameras],
  );

  const tagPoints = useMemo(
    () =>
      props.tags.reduce((values, { position: { x, y, z } }) => {
        const points: any = [
          new Vector3(x - 0.5, y - 0.5, z - 0.5),
          new Vector3(x + 0.5, y + 0.5, z + 0.5),
        ];
        values = values.concat(points);
        return values as Vector3[];
      }, [] as Vector3[]),
    [props.tags],
  );

  const bboxAll = useMemo(
    () => new Box3().setFromPoints(cameraPoints.concat(tagPoints)),
    [cameraPoints, tagPoints],
  );

  const position = useMemo(() => {
    const v = new Vector3();
    bboxAll.getCenter(v);
    return v;
  }, [bboxAll]);

  const [active, setActive] = useState<boolean>(false);
  const { spring } = useSpring({
    spring: active,
    config: { mass: 5, tension: 800, friction: 40, precision: 0.0001 },
  });
  const scale = spring.to([0, 0.75], [0.75, 1.1]);

  const { bbOpacitySpring } = useSpring({
    bbOpacitySpring: active,
    config: { mass: 5, tension: 200, friction: 80, precision: 0.0001 },
  });
  const bbOpacity = bbOpacitySpring.to([0.0, 0.001], [0.0, 0.00025]);

  const { isMobile } = useDeviceContext();

  // Retrieving hotspot data from global state
  const appState = useContext(StateContext);
  const hotspot: Marker = _.isFunction(appState?.getMarkerByRoomId)
    ? appState?.getMarkerByRoomId(props.name)
    : {};
  const room: Room = useMemo(() => {
    if (_.isEmpty(hotspot)) {
      return {};
    }
    return typeof hotspot?.room !== 'number'
      ? appState.getRoomById(hotspot?.room.id)
      : {};
  }, [props.name, hotspot]);
  const title = hotspot?.name;
  const hotspotImage = hotspot?.cover?.formats?.medium.url || '';
  const designer = _.first(room?.designers)?.name;

  const handleGroupClicked = (e: any) => {
    if (isMobile) {
      setActive(!active);
    } else {
      _.isFunction(props.onGroupSphereClicked) &&
        props.onGroupSphereClicked(props.name);
    }
    e.stopPropagation();
  };

  return (
    <>
      {/* {props.alwaysShowBoundingBox || (
        <BBox
          bbox={bboxAll}
          color={'blue'}
          active={active}
          bbOpacity={bbOpacity}
        />
      )} */}
      <group position={position}>
        <a.group position-y={scale}>
          <a.mesh
            onClick={handleGroupClicked}
            onPointerDown={handleGroupClicked}
            onPointerEnter={() => setActive(true)}
            onPointerLeave={() => setActive(false)}
            scale-x={scale}
            scale-y={scale}
            scale-z={scale}
            onPointerOver={() => {
              console.log('[Group]: onPointerOver')
              setActive(true)
            }}
            onPointerOut={() => {
              console.log('[Group]: onPointerOut')
              setActive(false)}
            }
          >
            <sphereBufferGeometry attach="geometry" args={[1, 32, 16]} />
            <meshBasicMaterial
              depthTest={false}
              depthWrite={false}
              attach="material"
              color={props.groupSphereColor}
              toneMapped={false}
            />
          </a.mesh>
        </a.group>

        {!_.isEmpty(room) && (
          <Html
            style={{
              transition: 'all 0.35s',
              opacity: active ? 1 : 0,
              pointerEvents: active? undefined : 'none'
            }}
          >
            <GroupHotspot
              title={title}
              description={designer ? `Designed by ${designer}` : ''}
              imageUrl={hotspotImage}
              onClick={(e) => {
                _.isFunction(props.onGroupSphereClicked) &&
                  props.onGroupSphereClicked(props.name);
                  e.stopPropagation();
              }}
            />
          </Html>
        )}
      </group>
    </>
  );
};

const BBox = ({
  bbox,
  color,
  active,
  bbOpacity,
}: {
  bbox: Box3;
  color: string;
  active: any;
  bbOpacity: any;
}) => {
  const scale = useMemo(() => {
    const v = new Vector3();
    bbox.getSize(v);
    return v;
  }, [bbox]);
  const position = useMemo(() => {
    const v = new Vector3();
    bbox.getCenter(v);
    return v;
  }, [bbox]);

  return (
    <Box args={[1, 1, 1]} scale={scale} position={position}>
      {/* NOTE: Use >>animated<<.meshStandardMaterial when using springs*/}

      {/* @ts-ignore */}
      <animated.meshStandardMaterial
        depthTest={false}
        depthWrite={false}
        attach="material"
        color={color}
        toneMapped={false}
        opacity={bbOpacity}
        transparent
        side={DoubleSide}
      ></animated.meshStandardMaterial>
    </Box>
  );
};
