import styles from './style.module.css'
import React from 'react';
import Icon from '../icon/icon';
 function ProductHeader({name,floorName,sponsors,onCloseClicked}:any){
    return <div className={styles.wrapper}>

            <div className={styles.text}>
            <h3>{name}</h3> 
            <h6 className={styles.sponsor}>{sponsors}</h6> 
           
            </div>
            <div className={styles.close  + " button"}  onClick={()=>{
              onCloseClicked && onCloseClicked()
            }}>
            <Icon icon={"icon-close"}/>  </div>
           
        
            
    </div>

}

export default ProductHeader;