export interface IClock {
	nowSeconds(): number;
	now(): Date;
	nowUtc(): Date;
	nowISOString(): string;
}

export class Clock implements IClock {
	public nowSeconds(): number {
		return new Date().getTime() / 1000;
	}

	public now(): Date {
		return new Date();
	}

	public nowUtc(): Date {
		var date = new Date();
		return new Date(
			date.getUTCFullYear(),
			date.getUTCMonth(),
			date.getUTCDate(),
			date.getUTCHours(),
			date.getUTCMinutes(),
			date.getUTCSeconds(),
			date.getUTCMilliseconds()
		);
	}

	public nowISOString(): string {
		return this.now().toISOString().split('.')[0] + 'Z';
	}
}
