import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import useState from 'react-usestateref';
import _ from 'lodash';

// import { useCookies } from "react-cookie";
// import CookieConsent from "../components/cookie_consent/cookie_consent";
// import { firebase, analytics } from "./firebase";

import { useRouter } from 'next/dist/client/router';
import { AnalyticsContext } from './analytics_provider';
import { ProductsContext } from './products_provider';
import {
  Camera,
  CameraControls,
  CameraType,
  Designer,
  Marker,
  Media,
  Meta,
  Product,
  Room,
  Site,
  ViewformConfig,
} from '@/types';
import { getGroupsFromTour } from '@/utils/viewform-utils';
import { IViewformTour } from '@vrpm/viewform-contracts';

export const StateContext = createContext<any>({
  playingAudio: null,
  tourUrl: null,
  meta: null,
  product: null,
  camera: null,
  designer: null,
  room: null,
  cardStack: null,
  mouse: null,
  dimension: null,
  showProductCard: null,
  showRoomCard: null,
  showDesignerListCard: null,
  showImageOverlay: null,
  showIntroVideo: null,
  showBoundaryExplorerIntro: null,
  //TODO: hook up to cookie consent widget
  showCookieConsent: null,
  showMap: null,
  openProduct: null,
  closeProduct: null,
  focus: null,
  openImageGallery: null,
  closeImageGallery: null,
  galleryStartIndex: null,
  gallery: null,

  enableCameraControls: null,
  disableCameraControls: null,
  registerCameraControls: null,
  setCurrentObject: null,
  setCurrentLevel: null,
  toggleAudioPlaying: null,
  getMarkerById: null,
  gotoRoom: null,
  openIntro: null,
});

export type StateProviderProps = {
  roomId?: string;
  markers: Marker[];
  cameras: Camera[];
  site: Site;
  roomData: Room[];
  viewformConfig: ViewformConfig;
  children: React.ReactNode;
  tour: IViewformTour;
};

export function StateProvider({
  children,
  roomId,
  site,
  cameras,
  markers,
  roomData,
  viewformConfig,
  tour,
}: StateProviderProps) {
  const mouse = useRef({
    x: 0,
    y: 0,
  });
  const [dimension, setDimension] = useState(3);
  const router = useRouter();
  const tracker = useContext(AnalyticsContext);
  const products = useContext(ProductsContext);

  const { pid }: any = router.query;
  var [explorerCameras, setExplorerCameras]: any = useState();
  var [product, setProduct]: any = useState(null);
  var [galleryStartIndex, setGalleryStartIndex]: any = useState(null);
  var [gallery, setGallery]: any = useState(null);
  var [camera, setCamera, cameraRef]: any = useState(null);
  var [rotation, setRotation, rotationRef] = useState<number>(0);
  var [designer, setDesigner] = useState<Designer>();
  var [rooms, setRooms, roomsRef] = useState<Room[]>([]);
  var [cardStack, setCardStack] = useState([]);
  var [showProductCard, setShowProductCard, showProductCardRef] =
    useState<boolean>(false);
  var [roomCardStates, changeRoomCardStates, roomCardStatesRef] = useState<
    string[]
  >([]);
  const [activeFloorRooms, setActiveFloorRooms] = useState<Room[]>();
  var [showDesignerListCard, openDesignerListCard] = useState<boolean>(false);
  var [showImageOverlay, openImageOverlay] = useState<boolean>(false);
  var [showIntroVideo, openVideoIntro] = useState<boolean>(false);
  var [showSponsorCard, openSponsorCard] = useState<boolean>(false);

  var [showBoundaryExplorerIntro, openBoundaryExplorerIntro] = useState(false);
  var [showCookieConsent, openCookieConsent] = useState(false);
  var [showMap, setOpenMap] = useState(false);
  var [playingAudio, setChangePLayingAudio] = useState<Media | null>();

  const [currentObject, setCurrentO] = useState<object>();
  const [currentLevel, setLevel] = useState(1);
  const [cc, setCC] = useState<CameraControls>();

  const tourUrl = 'https://tours.viewform.co/6149bef0cc3e6810c71bee87';
  const tourGroups = useMemo(() => getGroupsFromTour(tour), [tour]);
  const [interactionCount, setinteractionCount, interactionCountRef] =
    useState(0);
  const getMeta = (prod?: Product) => {
    const sharePageMessage = 'Check out iconic house';
    const sharePageUrl = '';
    const shareImageUrl = sharePageUrl + '/share-images/sharePageImage.jpg';
    const siteDescription = site.seoDescription;
    const siteTitle = site.seoTitle;
    if (prod) {
      return {
        title: prod.title + ' | ' + siteTitle,
        desc: prod.description,
        // img: sharePageUrl + prod.shareImageUrl,
        // url: sharePageUrl + '?pid=' + prod.id,
        favicon: site.favicon.url,
        product: prod,
      };
    } else {
      return {
        title: siteTitle,
        desc: siteDescription,
        // img: shareImageUrl,
        // url: sharePageUrl,
        favicon: site.favicon.url,
        product: null,
      };
    }
  };
  const [meta, setMeta] = useState(getMeta(pid && products.get(pid)));

  const getCamerasByType = (type: CameraType) => {
    return _.filter(cameras, { type }) || [];
  };

  const getRoomStartCamera = (roomId: string) => {
    const room = roomData.find((r) => roomId == r.roomId);
    if (room) {
      return room.startCamera;
    }
    return {};
  };

  //------------------------Map related functions ------------------------------------
  const gotoRoom = (roomId: string) => {
    try {
      var room = roomData.find((r) => roomId == r.roomId);
      console.log(roomId, room, rooms);
      var marker = room?.markers[0];
      console.log(marker);
      if (marker) {
        openMarkerFromMap(marker?.id);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const openMap = () => {
    setOpenMap(true);
  };
  const openMarkerFromMap = (markerId: number) => {
    console.log(markers, markerId);
    const marker = markers.find((marker) => marker.id == markerId);
    ////console.log(marker);

    if (marker) {
      const markerCameraId =
        typeof marker.camera === 'number'
          ? marker.camera
          : marker.camera.cameraId;
      const markerRoomId =
        typeof marker.room === 'number' ? marker.room : marker.room.roomId;
      tracker.logGotoRoom(markerCameraId, markerRoomId, tourUrl);

      setCamera(marker.camera);
      setRotation(marker.startRotation);
      closeProduct(true);
      closeMap();
    }
  };
  const closeMap = () => {
    setOpenMap(false);
  };

  const getMarkerById = (id: number) => {
    return markers?.find((e) => e.id == id);
  };

  const getMarkerByRoomId = (roomId: string) => {
    return markers?.find((m) => {
      if (typeof m.room === 'number') {
        return false;
      }
      return m?.room?.roomId === roomId;
    });
  };

  const getRoomById = (id: number) => {
    return roomData.find((e) => e.id == id);
  };

  const getRoomsByFloor = (floor: number) => {
    return roomData.filter((r) => r.floor === floor);
  };

  //------------------------Model related functions ------------------------------------

  const enableCameraControls = () => {
    if (!cc) return;
    cc.enabled = true;
  };
  const disableCameraControls = () => {
    if (!cc) return;
    cc.enabled = false;
  };
  const registerCameraControls = (controls: CameraControls) => {
    setCC(controls);
  };
  const setCurrentObject = (obj: object) => {
    setCurrentO(obj);
  };
  const setCurrentLevel = (obj: number) => {
    setLevel(obj);
  };

  const getActiveExteriorRooms = (floor: number) => {
    const roomIds = getRoomsByFloor(floor)?.map((r) => r.roomId);

    return tourGroups.filter((g) => _.includes(roomIds, g.name));
  };
  const toggleDimension = () => {
    setDimension(dimension == 3 ? 2 : 3);
  };

  //------------------------Product related functions ------------------------------------

  // First run the spring, when it concludes run the transition
  const openProduct = (productId: string, replaceRoute: boolean) => {
    if (!productId) throw 'Product Id not defined';

    //TODO: swap out
    const prodId = productId;
    const product = products.getByHotspotId(prodId);
    ////console.log(product, prodId, products);
    if (!product) return;
    tracker.logOpenProduct(
      product.id,
      product.title,
      product.room.roomId,
      tourUrl,
    );
    setProduct(product);
    setShowProductCard(true);

    toggleRoomCards(false, 'hidden');
    setMeta(getMeta(product));
    /*if (replaceRoute)
            router.replace(
                {
                    pathname: "",
                    query: { pid: product.id }
                },
                undefined,
                { shallow: true }
            );*/
    focus('card');
  };
  const closeProduct = (replaceRoute: boolean) => {
    setShowProductCard(false);
    setMeta(getMeta());
    /* if (replaceRoute)
            router.replace(
                {
                    pathname: ""
                },
                undefined,
                { shallow: true }
            );*/

    focus('explorer');
    if (!product) return;
    tracker.logCloseProduct(
      product.id,
      product.title,
      product.room.roomId,
      tourUrl,
    );
  };

  const clickedExternalProductLink = (product: Product) => {
    if (product) {
      const productRoomId =
        typeof product.room === 'number' ? product.room : product.room.roomId;

      tracker.logGotoExternalProductPage(
        product.id,
        product.title,
        productRoomId,
        product.externalLink,
        tourUrl,
      );
    }
  };

  //------------------------Intro related functions ------------------------------------

  const onIntroDone = () => {
    //console.log("onDone");
    openBoundaryExplorerIntro(false);
    openSponsorCard(true);
  };
  const openIntro = () => {
    openBoundaryExplorerIntro(true);
    openSponsorCard(true);
    //tracker.logStartTour();
  };
  const onSponsorDone = () => {
    openSponsorCard(false);
  };
  //------------------------Explorer related functions ------------------------------------

  const changeRoomTimeout: any = useRef();
  const currentRoomId: any = useRef(null);
  const onCameraWarp = (cameraId: string) => {
    //console.log("CAMERA WARP");
    if (!cameraId) return;

    //get camera name from tour id
    closeProduct(true);
    toggleRoomCards(false, 'mini');

    const cameraData = cameras.find(
      (e) => e.cameraId == cameraId || e.viewformCameraId === cameraId,
    );
    if (cameraData == null) {
      ////console.log("no camera mapping found", cameraId);
      return;
    }
    incrementInteractionCount();

    const cameraRoomId =
      typeof cameraData.room === 'number'
        ? cameraData.room
        : cameraData.room?.roomId;
    if (cameraRoomId) {
      tracker.logWarpToCamera(cameraData.cameraId, cameraRoomId, tourUrl);
    }

    if (
      cameraRef.current != null &&
      !_.isEmpty(cameraRef.current?.room) &&
      cameraRoomId != cameraRef.current?.room?.roomId
    ) {
      //new room entered
      updateRoom(cameraRoomId as string);
    }
    setCamera(cameraData);

    ////console.log(cameraData, cameraRef.current, "camera", cameraId);
  };
  const updateRoom = (roomId: string) => {
    if (currentRoomId.ref && currentRoomId.ref == roomId) {
      // Abort if roomId didn't change:
      return;
    }

    currentRoomId.ref = roomId;

    const currentRooms = roomData.filter(
      (e) =>
        e.roomId == roomId ||
        (roomId == 'terrace' && e.roomId == 'architecture'),
    );
    /*//console.log(
            "in new room!",
            cameraData.room.roomId,
            cameraData.cameraId,
            currentRooms
        );*/

    changeRoomCardStates(currentRooms.map((e) => 'hidden'));
    if (changeRoomTimeout.current) clearTimeout(changeRoomTimeout.current);
    changeRoomTimeout.current = setTimeout(
      () => {
        if (roomId == null) {
          // Current camera has no group, hide card:
          toggleRoomCards(false, 'mini');
        } else if (!_.isEmpty(currentRooms)) {
          setCurrentLevel(currentRooms[0].floor);
          setRooms(currentRooms);
          setChangePLayingAudio(null);
          changeRoomCardStates(currentRooms.map((e) => 'mini'));
        }
      },
      roomCardStatesRef.current.length > 0 ? 300 : 0,
    );
  };
  const incrementInteractionCount = () => {
    if (interactionCountRef.current == 0) {
      tracker.logStartTour();
    }
    console.log(interactionCountRef.current);
    setinteractionCount(interactionCountRef.current + 1);
  };
  const onExplorerLoad = (cams: Camera[]) => {
    ////console.log("onExplorerLoad", cams);

    setExplorerCameras(cams);
    updateRoom('entry_salon');
  };

  const updateTourRotation = (rotation: number) => {
    incrementInteractionCount();
    setRotation(rotation);
  };
  const onTourClick = (e: unknown) => {
    closeProduct(true);
    if (!showProductCardRef.current) toggleRoomCards(false, 'mini');
  };

  //------------------------Focus related functions ------------------------------------
  const focusRef = useRef<string>();

  const focus = (focusTerm: string) => {
    if (focusTerm == focusRef.current) return;
    switch (focusTerm) {
      case 'explorer':
        focusExplorer();

        ////console.log("explorer in focus");
        break;
      default:
      ////console.log("focus with no action", focusTerm);
    }
    focusRef.current = focusTerm;
  };

  const focusExplorer = () => {
    const el: any = document.querySelector('#explorer');
    el?.focus();
  };

  //------------------------Room card related functions ------------------------------------

  const toggleRoomCard = (
    roomIndex: number,
    open: boolean,
    defaultState = 'hidden',
  ) => {
    var newRoomStates = roomCardStatesRef.current.map((_, index) => {
      if (index == roomIndex) return open ? 'expanded' : defaultState;
      else return defaultState;
    });
    changeRoomCardStates(newRoomStates);
    /*console.log(
            "toggleRoomCards",
            roomIndex,
            open,
            defaultState,
            newRoomStates
        );*/
    // focus(open? "card":"explorer")
  };
  const toggleRoomCards = (open: boolean, defaultState: string) => {
    roomsRef.current.map((_, index: number) => {
      toggleRoomCard(index, open, defaultState);
    });
  };

  //------------------------Image Overlay related functions ------------------------------------

  const openImageGallery = (startIndex: number, media: Media) => {
    setGallery(media);
    setGalleryStartIndex(startIndex);
    openImageOverlay(true);
    focus('card');
  };

  const closeImageGallery = () => {
    openImageOverlay(false);
  };

  //---------------------------Audio player related functiouns--------------------------------------

  const toggleAudioPlaying = (audio: Media) => {
    setChangePLayingAudio(audio);
    focus('card');
  };

  const updateRoute = (p: unknown, c: unknown, r: unknown) => {
    const params: any = {};
    if (p) params.pid = p;
    if (c) params.cid = c;
    if (r) params.r = r;
    console.log('updateRoute', params);
    router.replace(
      {
        pathname: '',
        query: params,
      },
      undefined,
      { shallow: true },
    );
  };

  return (
    <StateContext.Provider
      value={{
        tourUrl,
        meta,
        product,
        camera,
        rotation,
        designer,
        rooms,
        mouse,
        dimension,
        cardStack,
        playingAudio,
        openIntro,
        showProductCard,
        roomCardStates,
        changeRoomCardStates,
        showDesignerListCard,
        showImageOverlay,
        showIntroVideo,
        showBoundaryExplorerIntro,
        //TODO: hook up to cookie consent widget
        showCookieConsent,
        showMap,
        openProduct,
        closeProduct,
        focus,
        openImageGallery,
        closeImageGallery,
        galleryStartIndex,
        gallery,

        openMap,
        closeMap,
        gotoRoom,
        currentLevel,
        currentObject,

        enableCameraControls,
        disableCameraControls,
        registerCameraControls,
        setCurrentObject,
        setCurrentLevel,
        toggleDimension,
        toggleAudioPlaying,
        onCameraWarp,
        onExplorerLoad,
        toggleRoomCard,
        openMarkerFromMap,
        updateTourRotation,
        onTourClick,
        toggleRoomCards,
        getMarkerById,
        getRoomById,
        openSponsorCard,
        showSponsorCard,
        onIntroDone,
        onSponsorDone,
        clickedExternalProductLink,
        updateRoom,
        incrementInteractionCount,
        getMarkerByRoomId,
        getCamerasByType,
        getRoomStartCamera,
        getRoomsByFloor,
        getActiveExteriorRooms,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}
