import styles from './room-header.module.css'
import React from 'react';
import Icon from '../icon/icon';
 function RoomHeader({name,floorName,sponsors,onCloseClicked,onFloorplanClicked}:any){
    return <div className={styles.wrapper}>

            <div className={styles.text}>
            <h3>{name}</h3> 
            <h6 className={styles.sponsor}>{sponsors}</h6> 
           
            </div>
            <div className={styles.close  + " button"}  onClick={()=>{
              onCloseClicked && onCloseClicked()
            }}>
            <Icon icon={"icon-close"}/>  </div>
           
           {false && <div className={styles.floorplanButton + " actionButton dark expanded"} onClick={()=>{
              onFloorplanClicked && onFloorplanClicked()
            }}>
             <Icon icon={"icon-layers"}/>
           
            All Rooms &#38; Floorplans
            </div>}
            
    </div>

}

export default RoomHeader;