import _ from 'lodash';
import { Clock } from './clock';
import {
	ViewformTour,
	Euler,
	ProjectDetails,
	UploadStatus,
	Access,
	Regime,
	Stage,
	GroupDetails,
	SpotlightDetails,
	AttachmentDetails,
	AttachmentType,
	ICameraDetails,
	CameraDetails
} from '@vrpm/viewform-contracts';
import { EmptyViewFormCameraDetails, ViewformCameraDetails } from '@/types';

export default class ViewformUtils {
	public static default() {
		const clock = new Clock();
		return new ViewformTour(
			'',
			'',
			new Euler(0, 0, 0, ''),
			Stage.New,
			new ProjectDetails('', '', '', '', Access.None, Regime.Interior, '', 0, clock.nowUtc()),
			[],
			[],
			[],
			[],
			[],
			UploadStatus.Pending,
			'',
			[],
			'',
			[],
			clock.nowUtc(),
			'',
			clock.nowUtc(),
			'',
			75
		);
	}
}

export const getGroupsFromTour = (tour: ViewformTour): GroupDetails[] => {
	const groups: GroupDetails[] = tour.groups ? tour.groups.map((g) => {

		if (!g.spotlight)
		{
			g.spotlight = new SpotlightDetails();
			g.spotlight.name = 'default';
			g.spotlight.thumbnail = new AttachmentDetails('dummy_id', AttachmentType.Png, 'dummyfilename', 'desc', 0, 'dzalega', new Date(), false);
			g.spotlight.id = 'dummy';
		}

		g.annotations = []
		g.cameras = tour.cameras.filter((d) => {
			return d.groups.findIndex(({ name }) => name == g.name) > -1
		})

		g.annotations = tour.annotations.filter((d) => {
			return d.groups.findIndex(({ name }) => name == g.name) > -1
		})
		return g;
	}) : []


	return groups;
};


export const getCameraFromTour = (tour: ViewformTour,cameraId: string): ViewformCameraDetails | EmptyViewFormCameraDetails => {
	const { cameras}= tour || [];

	const camera = _.find(cameras, { id: cameraId});

	if (_.isEmpty(camera)) {
		return {
			type: 'emptyViewformCameraDetails'
		}
	} else {
		return {
			type: 'viewformCameraDetails',
			...camera
		}
	}
}