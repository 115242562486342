import { useState, useEffect } from 'react';
import { Camera, Navigation } from '@/types';
import _ from 'lodash';
import { useDeviceContext } from '@/contexts/device.context';

export type LandingScreenProps = {
  navigations: Navigation[];
  onNavigate?: (startCamera?: Camera) => void;
  onLoadingEnd?: () => void;
  tourLoaded: boolean;
  useNavigation: boolean;
};
export default function LandingScreen({
  onNavigate,
  navigations,
  tourLoaded = false,
  useNavigation = true,
  onLoadingEnd,
}: LandingScreenProps) {
  const [showNav, setShowNav] = useState<boolean>(false);
  const { isMobile} = useDeviceContext();

/**
 * NOTE: Adding an extra second before showing the nav options(Enter the Garage, Enter the House)
 * so that the user sees the full image of the house a bit longer
 */
  useEffect(() => {
    if (tourLoaded && useNavigation) {
      setTimeout(() => {
        setShowNav(true);
      }, 1000)
    }
  }, [tourLoaded, useNavigation]);

  if (tourLoaded && !useNavigation) {
    _.isFunction(onLoadingEnd) && onLoadingEnd();
    return null;
  }

  return (
    <div
      className={
        'fixed top-0 left-0 w-full h-full bg-black flex flex-col justify-center items-center'
      }
      style={{
        zIndex: 9999999999 /* incredibly big number to lay on top of viewform */,
      }}
    >
      <div className="absolute top-0 left-0 w-full h-full ">
        <img
          className={'object-cover w-full h-full'}
          src="/landing-screen.jpg"
          alt="Landing Screen"
        />
      </div>

      <div
        className={
          'w-full flex flex-col justify-center items-center relative text-white'
        }
      >
        <img
          className={'w-44 md:w-[200px] mb-4'}
          src="/TIH-logo-white.png"
          alt="The Iconic House logo"
        />
        <div className="flex justify-center items-center">
          <span>Presented by</span>

          <img
            className={'w-20 h-20 md:w-[100px] md:h-[100px]'}
            src="/AD-logo.svg"
            alt="AD logo"
          />
          <img
            className={'w-18 h-18 md:w-[75px] md:h-[75px]'}
            src="/BID-logo.svg"
            alt="BID logo"
          />
        </div>
      </div>

      {!tourLoaded && <div className="lds-dual-ring absolute left-0 right-0 bottom-0 top-0 m-auto" />}
      {useNavigation && (
        <div
          className="relative bg-white py-5 pt-8 md:pt-12 px-5  md:px-12 text-black my-2 mx-12 md:m-4 max-w-full"
          style={{
            borderRadius: isMobile ?'20px': '30px',
            opacity: tourLoaded && showNav ? 1 : 0,
            transition: '0.5s',
          }}
        >
          <div className={'flex flex-col justify-center items-center gap-4 md:gap-8 md:flex-row'}>
            {navigations.map((nav) => (
              <button
                key={nav.id}
                className="flex flex-col justify-center items-center "
                onClick={() => {
                  _.isFunction(onNavigate) && onNavigate(nav?.startCamera);
                }}
              >
                {nav.image?.url && (
                  <div className="w-full max-w-full h-auto  md:w-[350px] md:h-[200px] lg:w-[400px] lg:h-[250px] overflow-hidden"
                  style={{
                    maxHeight: isMobile ? '130px' : undefined
                  }}>
                    <img
                      className="object-cover w-full h-full max-h-full"
                      src={nav.image?.url}
                      alt={nav.label}
                    />
                  </div>
                )}
                <div className="text-black text-lg md:text-2xl font-bold mt-2 font-serif ">
                  {nav.label}
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
